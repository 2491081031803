@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
/*font-family: 'Oswald', sans-serif;*/

@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Cabin:ital@1&display=swap');
/*  font-family: 'Cabin', sans-serif;
*/

@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap');
/*  font-family: 'Teko', sans-serif;
*/

@import url('https://fonts.googleapis.com/css2?family=Skranji:wght@400;700&display=swap');



.header_favicon__C9mcL {
  width: 10px;
  height: 5px;
  transform: scale(0.8);
}

.header_parent__HJcIb ul {
  list-style: none;
}

.header_parent__HJcIb {
  width: 100vw;
  z-index: 100;
  color: white;
  display: flex;
  overflow: hidden;
  position: absolute;
}

@keyframes header_textColorCycle_name__NAzPZ {

  0%,
  100% {
    background-color: #101010;
  }

  33% {
    background-color: #fafafafb;
  }

  66% {
    background-color: #131313;
  }
}

.header_parent_wrapper__enYHY {
  position: fixed;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: 0.6s;
}

.header_parent_wrapper_margin__7yuEw {
  transition: 0.6s;
}

.header_logo__signature_and_button_parent__qxvXk {
  background: linear-gradient(to top, #0e0e0e, #1e1e1e, #202020, #323232);
  display: flex;
  flex-direction: row;
  width: 100vw;
  max-height: 80px;
  min-height: 30px;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: space-between;
}

.header_logo_and_signature__f2fxK {
  gap: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header_logo_item__PMDC5 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.header_header_logo__dXEIE {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: white 0px 0px 7px;
  transition: 0.6s;
}

.header_header_logo__dXEIE:hover {
  transition: 0.6s;
  transform: scale(0.9);
  cursor: pointer;
  box-shadow: white 0px 0px 12px;
}

.header_signature_item__Crqk1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 60px;
}

.header_header_signature__hT4gE {
  margin-top: 5px;
  transition: 0.5s;
  width: 100px;
  height: 30px;
}

.header_header_signature__hT4gE:hover {
  cursor: pointer;
  transition: 0.5s;
}

.header_buttons__8KloF {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.header_login_logout_list_item___Do_G {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  width: 100px;
  height: 60px;
}

.header_login_avatar__eVaAn {
  width: 20px;
  height: 20px;
}

.header_login_avatar__eVaAn:hover {
  cursor: pointer;
}

.header_avatar_and_tag__wK7yz {
  display: flex;
  flex-direction: row;
  align-items: center;

}

.header_login_button__j4ANi {
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  color: rgb(248, 248, 248);
  white-space: nowrap;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  transition: 0.6s;
  font-family: 'Courier New', Courier, monospace;
  font-size: 11px;
  font-weight: 700;
}

.header_first_menubar__O5gp6 {
  position: absolute;
  justify-content: flex-end;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-items: left;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  width: 220px;
  height: 550px;
  margin-left: -50px;
  margin-top: 615px;
  border-top: none;
  border-bottom-left-radius: 0.66rem;
  border-bottom-right-radius: 0.35rem;
  color: black;
  gap: 1px;
  z-index: 120;
  border-color: rgb(255, 255, 255);
  border-width: 2px;
  box-shadow: rgb(208, 208, 208) 0px 0px 10px;
}

.header_menulist_title__wP1ZW { 
  font-size: 14px;
  color: rgb(109, 109, 109);
  font-weight: 800;
}


.header_first_menubar_item__eq2_b:hover {
  background-color: rgb(19, 19, 19);
  transition: 0.5s;
  color: white;
  transform: scale(0.98);
  border-radius: 0.66rem;
}


.header_first_menubar_item__eq2_b {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  font-size: 11px;
  font-weight: 900;
  font-family: 'Courier New', Courier, monospace;
  color: rgb(51, 51, 51);
  transition: 0.5s;
  gap: 5px;
  background-color: rgba(244, 244, 244, 0.881);
  border-bottom-left-radius: 0.66rem;
  border-width: 2px;
  border-color: rgb(223, 223, 223);
  animation: header_Changebgcolor__DEBGY 15s infinite;
}

@keyframes header_Changebgcolor__DEBGY {
  30% {
    color: rgb(73, 73, 73);

  }

  60% {
    color: rgb(0, 191, 166);

  }

  90% {     

    color: #222222

  }
}

.header_first_menubar_item__eq2_b:nth-child(13) { 

  border-bottom-left-radius:0.66rem;
}

.header_first_menubar_item__eq2_b p {
  transition: 0.5s;
  white-space: nowrap;
}

.header_first_menubar_item__eq2_b p:hover {
  text-decoration: underline;
  transition: 0.5s;
}



.header_menu_chevron_image__kRqhy {
  position: relative;
  width: 8px;
  height: 10px;
  margin-left: 10px;
  transition: 0.5s;
}

.header_menu_chevron_image__kRqhy:hover {
  transform: scale(1.03);
  transition: 0.5s;
}

.header_login_button__j4ANi:hover {
  color: rgb(157, 157, 157);
  transition: 0.6s;
  cursor: pointer;
}

.header_menu_toggler_image__KzKcF {
  margin-top: 5px;
  width: 20px;
  height: 20px;
  transition: 0.5s;
}

.header_menu_toggler_image__KzKcF:hover {
  cursor: pointer;
  transform: scale(1.2);
  transition: 0.5s;
}

.header_hide__S_h8S {
  display: none;
}

.header_menu_bars_wrapper__Crz0k {
  gap: 1px;
  display: flex;
  margin-top: 0px;
  align-items: center;
  justify-content: center;
}

.header_menuitem__y9s02 {
  cursor: pointer;
}

.header_promo_message_first__hF9eM {
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgb(255, 255, 255);
  color: rgb(43, 43, 43);
  font-size: 11px;
  width: 100vw;
  padding: 5px;
}

.header_promo_code__A29q_ {
  color: orange;
}

@media (min-width: 200px) and (max-height:600px) and (orientation: portrait) {
  .header_logo__signature_and_button_parent__qxvXk { 
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    width: 100vw;
  }

  .header_menu_toggler_image__KzKcF { 
    transform: scale(0.8);
  }

  .header_signature_item__Crqk1 { 
    transform: scale(0.7);
width: 80px;
  }

.header_first_menubar__O5gp6 { 
  margin-top: 650px;
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
margin-left: -100px;
}

.header_first_menubar_item__eq2_b{
  gap: 0;
height: 40px;
width: 250px;
}

.header_logo__signature_and_button_parent__qxvXk { 
  gap: 0;
  padding: 5px;
}
.header_header_logo__dXEIE { 
display: none;
}
.header_signature_item__Crqk1 { 
  margin-left: -35px;
}

} 
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
/*font-family: 'Oswald', sans-serif;*/

@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Cabin:ital@1&display=swap');
/*  font-family: 'Cabin', sans-serif;
*/

@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap');
/*  font-family: 'Teko', sans-serif;
*/

.footer_footer__uPdXg ul { 
  list-style: none;
}

.footer_footer__uPdXg {
  background-color: #0c1215;
  color: #8b0000;
  padding: 10px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_wrapper__Iafam {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
width: 100vw;
}


.footer_separatorLine1__OsZ0X {
  border-color: #ffffffe7;
  border-radius: 50px;
  border-width: 2px;
  width: 80vw;
  margin-bottom: 0px;
  margin-top: 20px;
}

.footer_sections__3ayjx {
  width: 70vw;
  display: flex;
  flex-direction: row;
  list-style: none;
  transition: 0.2s;
  align-items: center;
  justify-content: space-between;
  height: -moz-max-content;
  height: max-content;
}

.footer_section__wRgSx {
  width: -moz-max-content;
  width: max-content;
  flex: 1;
  padding: 15px;
  align-items: center;
  justify-content: center;
}

.footer_list_sections__YHx6p {
  width: -moz-max-content;
  width: max-content;
  font-size: 9px;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.footer_avatarContact__IZxc6 {
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: -moz-max-content;
  width: max-content;
}

.footer_avatar__3XZXe {
  box-shadow: rgb(45, 14, 14) 0px 0px 10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  transition: 1s;
  margin-right: 0;
  border-width: 1px;
  border-color: rgb(255, 255, 255);
}


.footer_avatar__3XZXe:hover {
  transition: 1s;
  box-shadow: rgb(255, 0, 0) 0px 0px 10px;
  cursor: pointer;
  transform: scale(1.1);
}


.footer_astrumBottomName__4Aw7w {
  font-size: 15px;
  font-weight: bold;
  margin: 0;
  transition: 2s;
}

.footer_astrumBottomName__4Aw7w:hover {
  cursor: pointer;
  transition: 2s;
  color: rgb(231, 231, 231);
}

.footer_contactButton__wu5YA {
  box-shadow: rgb(171, 171, 171) 0px 0px 8px;
  background-color: #1e9218;
  border: none;
  padding: 7px 14px;
  border-radius: 5px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.6s;
}

.footer_contactButton__wu5YA:hover {
  transition: 0.6s;
  transform: scale(1.03);
  background-color: #0b3908;
}

.footer_contactParagraph__MuPsV {
  font-size: 9px;
  font-weight: 600;
  margin: 0;
}

.footer_pagesLinksServices__GGSmJ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}

.footer_sectionTitle__OIHSu {
  text-align: center;
  font-size: 12px;
  color: rgb(166, 166, 166);
  font-weight: bold;
  margin-bottom: 15px;
  cursor: pointer;
  white-space: nowrap;
}

.footer_list__Aejzu {
  display: block;
  width: -moz-min-content;
  width: min-content;
  text-align: center;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
}

.footer_item__kvH_x {
  white-space: nowrap;
  cursor: pointer;
  font-size: 10px;
  color: rgb(143, 143, 143);
  margin-bottom: 5px;
  transition: 0.2s;
}

.footer_item__kvH_x:hover {
  text-decoration: underline;
  color: rgb(255, 191, 0);
  transition: 0.2s;

}

.footer_iconWrapperqtq__uO64C {
display: flex;
max-width: 200px;
}

.footer_iconWrapperqtq__uO64C li {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  width: 100px;

}

.footer_imageIcon__nV4PT {
  border-radius: 50%;
  transition: 0.5s;
  width: 20px;
  height: 20px;
  margin: 20px;
}

.footer_imageIcon__nV4PT:hover {
  cursor: pointer;
  transition: 0.6s;
  transform: scale(0.8);
}

.footer_copyRight__X4om7 {
  transition: 0.5s;
  text-align: right;
  font-size: 6px;
  font-weight: 400;
  color: rgb(255, 102, 0);
}

.footer_copyRight__X4om7:hover {
    transition: 0.5s;
transform: scale(1.2);
}


@media (min-width: 200px) and (max-width: 300px) {

  .footer_footer__uPdXg {
    background-color: #0c1215;
    color: white;
    width: 100vw ;
    display: flex;
  }
  
  .footer_wrapper__Iafam {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
  }
  
  .footer_sections__3ayjx {
    display: flex;
    flex-direction: column;
    list-style: none;
    transition: 0.2s;
    align-items: center;
    justify-content: center;
    height: -moz-max-content;
    height: max-content;
  }

  .footer_list_sections__YHx6p {
    font-size: 6px;
    list-style: none;
    display: flex;
    flex-direction: row;
    gap: 0px;
  }

  .footer_list__Aejzu { 
   width: -moz-max-content; 
   width: max-content;
  } 
  .footer_sectionTitle__OIHSu { 
    font-size: 8px;
  }

  .footer_item__kvH_x { 
    font-size: 5px;
  }

  .footer_avatarContact__IZxc6 {
    transform: scale(0.6);
    gap: 10px;
  }

  .footer_iconsAndCopy__pwQSp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    flex-direction: column;
  }

}

@media (min-width: 300px) and (max-width: 400px) {

  .footer_footer__uPdXg {
    background-color: #0c1215;
    color: white;
    width: 100vw ;
    display: flex;
  }
  
  .footer_wrapper__Iafam {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
  }
  
  .footer_sections__3ayjx {
    display: flex;
    flex-direction: column;
    list-style: none;
    transition: 0.2s;
    align-items: center;
    justify-content: center;
    height: -moz-max-content;
    height: max-content;
  }

  .footer_list_sections__YHx6p {
    font-size: 7px;
    list-style: none;
    display: flex;
    flex-direction: row;
    gap: 0px;
  }

  .footer_sectionTitle__OIHSu {
    font-size: 10px;
  }

  .footer_avatarContact__IZxc6 {
    transform: scale(0.6);
    gap: 10px;
  }

  .footer_iconsAndCopy__pwQSp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    flex-direction: column;
  }

}



@media (min-width: 400px) and (max-width: 800px) {



  .footer_sections__3ayjx {
    display: flex;
    flex-direction: column;
    list-style: none;
    transition: 0.2s;
    align-items: center;
    justify-content: center;
    height: -moz-max-content;
    height: max-content;
  }


  .footer_list_sections__YHx6p {
    font-size: 9px;
    list-style: none;
    display: flex;
    flex-direction: row;
    gap: 0px;
  }

  .footer_sectionTitle__OIHSu {
    font-size: 10px;
  }

  .footer_avatarContact__IZxc6 {
    transform: scale(0.6);
  }

  .footer_iconsAndCopy__pwQSp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    flex-direction: column;
  }

}



